// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-afhentning-tsx": () => import("./../../../src/pages/afhentning.tsx" /* webpackChunkName: "component---src-pages-afhentning-tsx" */),
  "component---src-pages-catering-tsx": () => import("./../../../src/pages/catering.tsx" /* webpackChunkName: "component---src-pages-catering-tsx" */),
  "component---src-pages-faerdigretter-tsx": () => import("./../../../src/pages/faerdigretter.tsx" /* webpackChunkName: "component---src-pages-faerdigretter-tsx" */),
  "component---src-pages-frokostordning-tsx": () => import("./../../../src/pages/frokostordning.tsx" /* webpackChunkName: "component---src-pages-frokostordning-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-kurv-tsx": () => import("./../../../src/pages/kurv.tsx" /* webpackChunkName: "component---src-pages-kurv-tsx" */),
  "component---src-pages-tak-tsx": () => import("./../../../src/pages/tak.tsx" /* webpackChunkName: "component---src-pages-tak-tsx" */),
  "component---src-templates-ugemenu-tsx": () => import("./../../../src/templates/ugemenu.tsx" /* webpackChunkName: "component---src-templates-ugemenu-tsx" */)
}

